import { AuthorizationConfig, GeneralEnvironmentInfo } from '../app/Service/authorization_config';
import { AuthorizationConfigExternal, GeneralEnvironmentInfoExternal } from '../app/Service/authorization_config_external';

export const environment: AuthorizationConfig & GeneralEnvironmentInfo = {
    production: true,
    issuer_uri: 'https://wiam-np.bpost.cloud', //need to modify
    client_id: 'lockerinternal',
    redirect_uri: 'https://parcellockers-st2.bpost.cloud/login/bpost/callback',
    baseUrl: "https://parcellockers-st2.bpost.cloud/login/bpost/callback",
    pingAuthorizationUrl: "https://wiam-np.bpost.cloud/as/authorization.oauth2",
    pingTokenUrl: "https://wiam-np.bpost.cloud/as/token.oauth2",
    pingUserProfileUrl: "https://wiam-np.bpost.cloud/idp/userinfo.openid",
    pingLogout: 'https://wiam-np.bpost.cloud/idp/startSLO.ping?',
};

export const pingEnvironment: AuthorizationConfigExternal & GeneralEnvironmentInfoExternal = {
    production: true,
    issuer_uri: 'https://ssociam-np.bpost.cloud', //need to modify
    client_id: 'nlp_web_ciam_np',
    redirect_uri: 'https://parcellockers-st2.bpost.cloud/login/external/callback',
    pingRegistrationUrl: 'https://ssociam-np.bpost.cloud/pf-ws/authn/flows',
    pingAuthorizationUrl: 'https://ssociam-np.bpost.cloud/as/authorization.oauth2?',
    pingLogout: 'https://ssociam-np.bpost.cloud/idp/startSLO.ping?',
};

export const ddsEnvironment = {
    production: true,
    apiUrl: "https://api.dds-st2.bpost.cloud",
    baseUrl: "https://api.dds-st2.bpost.cloud",
    apiKey: 'XxoeUPFFwL3p4gtaEJtFs8F3NiOECHns7hHiXr8G'
};

export const lrsEnvironment = {
    production: true,
    baseUrl: "https://api.lrs-st2.bpost.cloud",
    apiKey: 'XxoeUPFFwL3p4gtaEJtFs8F3NiOECHns7hHiXr8G'
};

export const losEnvironment = {
    production: true,
    baseUrl: "https://api.los-st2.bpost.cloud",
    apiKey: 'XxoeUPFFwL3p4gtaEJtFs8F3NiOECHns7hHiXr8G'
};

export const userEnvironment={
    basic_env:'st2',
    baseUrl: "https://parcellockers-st2.bpost.cloud/login/bpost/callback",
};
